header {
  width: 100%;
  padding: 15px 0;
  border-top: 2px solid #0064e9;
  box-shadow: 0 0 20px #c3c7cd;
}

/* .header-top {
  margin-bottom: 15px;
} */

.cm-header-logo {
  width: 220px;
}

.cm-header-logo img {
  width: 100%;
  display: block;
}

/* 
.cm-header-op {
  width: calc(100% - 170px);
  justify-content: flex-end;
} */

.cm-header-phone {
  position: relative;
}

.cm-header-phone img {
  width: 44px;
  position: absolute;
  left: -52px;
  top: 0px;
}

.cm-header-phone a {
  font-size: 20px;
  font-weight: 800;
  color: #337ab7;
}

.cm-header-phone span {
  font-size: 13px;
  font-weight: 600;
  line-height: .5;
  display: block;

}

.cm-header-phone>i {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 14px;
  background-color: #337ab7;
}

.mobile-view {
  display: none;
}

/* header bottom */

.header-bottom ul.bottom-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

li.active a {
  color: #0064e9;
}


.header-bottom ul li {
  list-style: none;
  margin: 0 5px;
  padding: 5px 10px;
}

.header-bottom ul li a {
  font-size: 16px;
  transition: .3s;
  font-weight: 600;
}

.active-link {
  color: red;
}

.header-bottom ul li a:hover {
  color: #0064e9;
}

.airlines-dropdown {
  position: relative;
}

.airlines-dropdown ul {
  box-shadow: 4px 10px 10px #ccc;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  top: 36px;
  left: -47px;
  width: 250px;
  height: 400px;
  overflow: auto;
}

.airlines-dropdown ul li {
  width: 100%;
  border-bottom: 1px dotted;
}

.airlines-dropdown ul li {
  margin: 0;
  padding: 0;
}

.airlines-dropdown ul li:hover {
  background-color: #d4e6ff;
}


/* width */
.airlines-dropdown ::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.airlines-dropdown ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.airlines-dropdown ::-webkit-scrollbar-thumb {
  background: #0064e9;
  border-radius: 5px;
}

/* Handle on hover */
.airlines-dropdown ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

li.airlines-dropdown ul a {
  margin-left: 10px;
  padding: 10px 10px;
  display: block;
}

.airlines-dropdown a i {
  font-size: 14px;
}

.mobile-menu {
  display: none;
}

.header-bottom ul.bottom-nav li i {
  display: none;
}

@media(max-width: 1260px) {
  .header-bottom ul li {
    margin: 0 10px;
    padding: 5px 10px;
  }
}

@media(max-width: 960px) {
  .header-bottom ul li {
    margin: 0 8px;
    padding: 5px 8px;
    font-size: 14px;
  }
}

@media(max-width:767px) {

  .navBarmenu {
    display: none;
  }

  .navBarmenu.active {
    display: block;
  }

  .header-top {
    display: none;
  }

  .header-bottom {
    position: relative;
  }

  .mobile-menu {
    display: block;
  }

  .header-bottom ul.bottom-nav {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: absolute;
    background: #fff;
    z-index: 9;
    top: 13px;
    left: 0;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
  }

  .header-bottom ul.bottom-nav li {
    padding: 5px 15px 5px 20px;
    margin: 10px 0 0;
    border-bottom: 1px solid #ccc;
    position: relative;
  }

  .header-bottom .airlines-dropdown ul li {
    padding: 0;
    margin: 0;
  }

  .header-bottom ul.bottom-nav li i {
    margin-right: 5px;
    display: inline-block;
  }

  .header-bottom ul.bottom-nav li:last-of-type {
    border-bottom: 0;
  }

  .header-bottom ul li a {
    font-size: 14px;
  }

  .airlines-dropdown>ul li:hover {
    background-color: gray;
  }

  .airlines-dropdown ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    position: absolute;
    background: #fff;
    z-index: 9;
    top: 30px;
    left: 0px;
    /* height: 100vh; */
    justify-content: flex-start;
  }

  .airlines-dropdown ul li {
    margin: 5px 0;
    padding: 5px 0px;
    position: relative;
  }

  .airlines-dropdown a i {
    font-size: 14px;
    position: absolute;
    right: 15px;
    top: 8px;
  }

  .cm-expert {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
  }

  .cm-expert img {
    width: 35px;
  }

  .cm-expert a,
  .cm-expert p {
    font-size: 12px;
    font-weight: 700;
    margin-left: 5px;
  }

  .mobile-menu-top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .mobile-menu-bottom {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .mobile-menu-bottom i {
    font-size: 16px;
  }
}

@media(max-width: 600px) {
  header {
    padding: 9px 0 13px;
  }

  .cm-header-logo {
    width: 140px;
  }

  .cm-header-phone a {
    font-size: 13px;
  }

  .cm-header-phone img {
    width: 26px;
    left: -30px;
    top: 4px;
  }

  .cm-header-phone span {
    font-size: 8px;
  }
}