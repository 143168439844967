.cm-fl-loader-wrapper {
  max-width: 450px;
  padding: 30px 15px;
  border-radius: 15px;
  margin: 0 auto;
}

.cm-fl-loader-wrapper i {
  padding-right: 5px;
  color: var(--secCol);
}

.cm-fl-loader-wrapper h3 {
  color: #009bc7;
  margin-bottom: 10px;
}

.cm-fl-loader-wrapper p {
  margin-bottom: 20px;
}
/* .lodor-section{
  margin-top: 90px;
} */

  @media(max-width: 767px){
    .cm-fl-loader-wrapper{
      max-width: 90%;

    }
    .cm-fl-loader-wrapper h4{
      font-size: 20px;
    }
  }