.ryanair-title {
    display: inline-block;
    margin-bottom: 15px;
}

.ryanair-title h1 {
    position: relative;
}

.ryanair-title h1 span {
    color: #0064e9;
}

.ryanair-title h1::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 50%;
    height: 1px;
    background-color: #0064e9;
}

.ryanair-title h1::before {
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    width: 50%;
    height: 1px;
    background-color: #0064e9;
}

.ryanair-content p {
    margin-bottom: 8px;
    font-size: 16px;
}

.ryanair-content h3 {
    margin-bottom: 10px;
}

.ryanair-content ul li {
    list-style: none;
}

.ryanair-content ul li h4 {
    font-size: 18px;
    margin-bottom: 5px;
}

@media(max-width:767px) {
    .ryanair-title {
        display: inline-block;
        margin-bottom: 10px;
    }

    .ryanair-content p {
        margin-bottom: 5px;
        font-size: 14px;
    }

    .ryanair-content h3 {
        font-size: 18px;
    }

    .ryanair-content ul li h4 {
        font-size: 14px;
        margin-bottom: 5px;
    }
}