.head-image {
    height: 300px;
    margin-bottom: 15px;
}

.head-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.name-change-content h1 {
    font-size: 36px;
}

.name-change-content h2 {
    font-size: 26px;
}

.name-change-content h3 {
    font-size: 22px;
}

.name-change-content p,
.name-change-content li {
    font-size: 16px;
}

.name-change-content h1,
.name-change-content h2,
.name-change-content h3,
.name-change-content p,
.name-change-content li {
    margin-bottom: 15px;
}

.name-change-content ul {
    padding-left: 20px;
}

@media(max-width:767px) {
    .head-image {
        height: 150px;
        margin-bottom: 10px;
    }

    .name-change-content h1 {
        font-size: 22px;
    }

    .name-change-content h2 {
        font-size: 20px;
    }

    .name-change-content h3 {
        font-size: 18px;
    }

    .name-change-content p,
    .name-change-content li {
        font-size: 14px;
    }

    .name-change-content h1,
    .name-change-content h2,
    .name-change-content h3,
    .name-change-content p {
        margin-bottom: 8px;
    }
}

/* united name change */
.united-footer.cm-footer-container {
    padding: 15px 0 0 !important;
}

.united-name-change h1,
.united-name-change h2,
.united-name-change h3,
.united-name-change a {
    color: #024;
}

.united-header a {
    color: #024;
}

.united-footer {
    background-color: #024;
}

.united-footer h3,
.united-footer ul li a,
.united-footer ul li i {
    color: #fff;
}

.united-footer ul li {
    margin-top: 0 !important;
}

.united-footer ul li a {
    font-size: 12px !important;
}

.united-footer .cm-footer-top .cm-footer-col h3 {
    font-size: 14px;
    margin-bottom: 5px;
}